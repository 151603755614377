

export const environment = {
  production: false,
  baseUrl: "https://api.consignment.kanhaiya.co.in/index.php/",
  mediaURL: 'https://api.consignment.kanhaiya.co.in/uploads/',
  authToken: 'consignment',
  firebase: {
    apiKey: "AIzaSyDxLYCop7kgMKrMCjlnrlJBJWcXB1lByTU",
    authDomain: "chatapp-5e797.firebaseapp.com",
    projectId: "chatapp-5e797",
    storageBucket: "chatapp-5e797.appspot.com",
    messagingSenderId: "409361595207",
    appId: "1:409361595207:web:48ef145edd4291b956ea62"
  },
  apiUrls: {

    apis: {

      savelog: 'logs/save',
      getlogs:'logs/getAll',

      Login: 'users/loginUser',
      getParameterList: 'parameter/getAll',
      updateSettings:'parameter/editList',

      saveusers: 'users/registerUser',
      updateusers: 'users/editList',
      getusers: 'users/getById',
      users:'users',

      consignmentList: 'consignment/getAll',
      lcList: 'consignment/getLRAll',
      saveConsignment: 'consignment/save',
      updateConsignment: 'consignment/editList',
      getConsignment: 'consignment/getById',
      getByIdOnlyData: 'consignment/getByIdOnlyData',
      getByConsignmentNo: 'consignment/getByConsignmentNo',
      getConsignmentReport: 'consignment/getReport',
      getConsignmentBySupplier: 'consignment/getConsignmentBySupplier',
      saveConsignmentBankAccount: 'consignment/savebankaccount',
      deleteConsignmentBankAccount: 'consignment/deletebankaccount',
      getbankdetails: 'consignment/getbankdetails',
      releaseadvance: 'consignment/releaseadvance',
      releasepayment:'consignment/releasepayment',
   
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
